import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import slid_img1_key from "../../assets/images/h1.jpg";
import slid_img2_key from "../../assets/images/h2.jpg";
import slid_img3_key from "../../assets/images/h3.jpg";
import slid_img4_key from "../../assets/images/h4.jpg";
import slid_img5_key from "../../assets/images/h5.jpg";
import slid_img6_key from "../../assets/images/h6.jpg";
import yello from '../../assets/images/yellowLogo.jpg'
import trending_headimg from "../../assets/images/head_img.jpg";

import Slider from "react-slick";
import Propsgeneralphysicians from "./Propsgeneralphysicians";
import "./Generalphysicians.css";

function Generalphysicians() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    // autoplay:true,
    slidesToScroll: 1,
    responsive: [
      {
          breakpoint: 1024,
          settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
          }
      },
      {
          breakpoint: 600,
          settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
          }
      },
      {
          breakpoint: 480,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
          }
      }
  ]
  }
  return (
    <div className="generalphysicians_holder">
      <div className="blue_background"></div>
      <div className="slid_hole">
      <Container>
        <Slider {...settings}>
            <Propsgeneralphysicians ethvalue3='£1,200' link={'https://www.spareroom.co.uk/17608017'} img={slid_img1_key} title='Flat share Bristol BS3' btnclick='LivingList'yellowimg={yello}count='4/75'ruppies='price'ethvalue='£1,000 'highestbid='highest Bid'ethvalue2='Now'imgsml={trending_headimg}/>
            
            <Propsgeneralphysicians ethvalue3='£1,850' link={'https://www.spareroom.co.uk/17595839'} img={slid_img2_key} title='Flat to rent Bristol BS1'btnclick='LivingList'count='34/135'ruppies='price'ethvalue='£1,200 'highestbid='highest Bid'ethvalue2='Now'imgsml={trending_headimg}/>
            
            <Propsgeneralphysicians ethvalue3='£1,250' link={'https://www.spareroom.co.uk/17602839'} img={slid_img3_key} title='Flat to rent London SW11 SW11'btnclick='LivingList'yellowimg={yello}count='9/75'ruppies='price'ethvalue='£1,800 'highestbid='highest Bid'ethvalue2='Now'imgsml={trending_headimg}/>
            
            <Propsgeneralphysicians ethvalue3='£550' link={'https://www.spareroom.co.uk/17595624'} img={slid_img4_key} title='House share Manchester M9'btnclick='LivingList'count='15/45'ruppies='price'ethvalue='£600 'highestbid='highest Bid'ethvalue2='Now'imgsml={trending_headimg}/>
            
            <Propsgeneralphysicians ethvalue3='£1,50' link={'https://www.spareroom.co.uk/17595839'} img={slid_img5_key} title='Flat to rent London SW11 SW11'btnclick='LivingList'yellowimg2={yello}count='9/75'ruppies='price'ethvalue='£1,800 'highestbid='highest Bid'ethvalue2='Now'imgsml={trending_headimg}/>
        </Slider>
        
    </Container>
      </div>
    </div>
  );
}

export default Generalphysicians;
