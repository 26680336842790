import React from 'react'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faStar,faAngleRight} from '@fortawesome/free-solid-svg-icons';
import './Generalphysicians.css';

const Propsgeneralphysicians =(props)=> {
  return (
    <div>
    <a href={props.link} target='_blank' style={{textDecoration:'none',marginRight:'2px'}}>
    <div class="trendingnftslider-mg position-relative"style={{marginRight:'12px'}}>
    <div class=" trendingnft_slider_img">
         <img class="img-fluid" style={{width:'100%',height:'190px',objectFit:'cover'}}  src={props.img} alt="" />
     </div>
     <div className="trendin_heading_detail">
       <Link to="/" className='btn_trending_slider'>{props.btnclick}</Link>
          <div className='hold_by position-relative d-flex'>
            <h3>{props.title}</h3>
            
          </div>
     </div>
     <div className='main_hold_left_right d-flex'>
        <div className="left_side"><h3>{props.ruppies}<span className='eth_cls d-block'>{props.ethvalue}</span></h3></div>
       <div className="right_side"><h3>{'Security deposit'}<span className='eth_cls d-block'>{props.ethvalue3}</span></h3></div>
     </div>
</div>
</a>
</div>
  )
}

export default Propsgeneralphysicians