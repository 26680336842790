
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEnvelope,} from '@fortawesome/free-regular-svg-icons';
import React from 'react'
import { faDiscord ,faMailchimp,faTelegram,faTwitter,faWhatsapp,faYoutube} from '@fortawesome/free-brands-svg-icons';
import { faArrowRightToBracket, faCamera, faMailBulk, faMailForward, faMailReply, faMailReplyAll, faPhone, faVoicemail } from '@fortawesome/free-solid-svg-icons';
import {Container ,Row,Col, Navbar, Nav,} from "react-bootstrap";
import curelogo_key from "../../assets/images/logo.png";
import whatsApp from "../../assets/images/whatsapp.png";
import gMail from "../../assets/images/gmail.png";
import phone from "../../assets/images/phone1.png";
import { Link } from "react-router-dom";
import "./Header.css";
function Header() {
  const phoneNumber = "+447591605716"; // Replace with your actual phone number
  const defaultMessage = "Hello, I need assistance!"; // Default message

  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(defaultMessage)}`;

  // Define the email details
const emailAddress = "livinglinks@property.co.uk"; // Replace with your actual email address
const emailSubject = "Inquiry about services"; // Default subject
const emailBody = "Hello, I would like to inquire about your services."; // Default body message

// Create the Gmail link
const gmailLink = `mailto:${emailAddress}?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`;
//phone
const teleNumber = "+441615157327"; 
const phoneLink = `tel:${teleNumber}`;
  return (
    <header id="header">
      <div className="head_top ">
      <div className='container-fluid'>
        <Row className=''>
          <Col sm={2} xs={4}>
              <strong className="logo ">
                  <Link to="/" className='logo_clss'>
                    <img class="img-fluid" width={200}  src={curelogo_key} alt="" />
                  </Link>
              </strong>
            </Col>
            <Col sm={7} xs={8} className='px-5 d-flex justify-content-center'>
            <div className="nav_holder d-flex">
                <Navbar expand="lg">
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto navlink-holder">
                      <ul className="nav_bar">
                        <li><Link to="/" className="nav_link">Tenants</Link></li>
                        <li><Link to="/" className="nav_link">Landloard</Link></li>
                        <li><Link to="/" className="nav_link">Renting</Link></li>
                        <li><Link to="/" className="nav_link">Rent to rent</Link></li>
                        <li><Link to="/" className="nav_link">Property Management services</Link></li>
                      </ul>
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>
                
              </div>
            </Col>
            <Col sm={3} className='d-flex justify-content-between px-5'style={{alignItems:"center"}}>
            
                    
                    
                      
                         <a href={whatsappLink} className='icon_hovering' target="_blank" rel="noopener noreferrer"><span className="foot_icons"><img className='headerIcons' width={60} src={whatsApp}  />  </span></a>
                       <a href={gmailLink} className='icon_hovering'><span className="foot_icons"><img className='headerIcons' width={60} src={gMail}  /></span></a>
                        <a href="tel:+441615157327" className='icon_hovering'><span className="foot_icons"><img className='headerIcons' width={60} src={phone}  /></span></a>
                      
                    
                 
            </Col>
        </Row>
      </div>
    </div>


  </header>
    
  )
}

export default Header
